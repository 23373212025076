exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../../src/pages/contact-success.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-documents-tsx": () => import("./../../../src/pages/documents.tsx" /* webpackChunkName: "component---src-pages-documents-tsx" */),
  "component---src-pages-emergencies-tsx": () => import("./../../../src/pages/emergencies.tsx" /* webpackChunkName: "component---src-pages-emergencies-tsx" */),
  "component---src-pages-equipment-tsx": () => import("./../../../src/pages/equipment.tsx" /* webpackChunkName: "component---src-pages-equipment-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sectors-tsx": () => import("./../../../src/pages/sectors.tsx" /* webpackChunkName: "component---src-pages-sectors-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-vacancies-tsx": () => import("./../../../src/pages/vacancies.tsx" /* webpackChunkName: "component---src-pages-vacancies-tsx" */),
  "component---src-templates-equipment-template-tsx": () => import("./../../../src/templates/EquipmentTemplate.tsx" /* webpackChunkName: "component---src-templates-equipment-template-tsx" */),
  "component---src-templates-news-index-template-tsx": () => import("./../../../src/templates/NewsIndexTemplate.tsx" /* webpackChunkName: "component---src-templates-news-index-template-tsx" */),
  "component---src-templates-news-template-tsx": () => import("./../../../src/templates/NewsTemplate.tsx" /* webpackChunkName: "component---src-templates-news-template-tsx" */),
  "component---src-templates-service-template-tsx": () => import("./../../../src/templates/ServiceTemplate.tsx" /* webpackChunkName: "component---src-templates-service-template-tsx" */)
}

